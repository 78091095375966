@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.container-breadcrumb {
  width: 100%;
  max-width: 360px;
}

.container-body {
  max-width: 1000px;
  width: 100%;
  margin-top: fn.spacing(4);
}

.separator {
  margin-top: fn.spacing(8.125);
  margin-bottom: fn.spacing(8.125);
  border: 1px solid #bec6d5;
  width: 100%;
}

.description-text {
  max-width: 340px;
}

.description-info {
  max-width: 340px;
  margin-top: fn.spacing(2.625);
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .container-breadcrumb {
    max-width: 970px;
  }

  .container-body {
    max-width: 900px;
    margin-top: fn.spacing(7.75);
  }

  .description-text {
    max-width: 100%;
  }

  .description-info {
    max-width: 100%;
  }
}

@media only screen and (min-width: var.$breakpoint-xl) {
  .container-breadcrumb {
    max-width: 1070px;
  }

  .container-body {
    max-width: 1000px;
  }
}
