@use 'styles/variables' as var;

.hero-media-content {
  position: relative;
  z-index: 1;
}

.image {
  opacity: 1;
}

.video {
  width: auto;
  min-width: 100%;
  min-height: 100vh;
  position: absolute;
  right: 0;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-on-landscape='true'] {
  .video {
    min-width: 100vw;
    height: auto;
    min-height: 100%;
  }
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: var.$breakpoint-lg) {
  .image,
  .video {
    opacity: 0.7;
  }
}
