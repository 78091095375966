@use 'styles/variables' as var;
@use 'styles/functions' as fn;

//Mobile styles
.closer-statement-section {
  background-color: var.$color-primary-highlight-blue;
  color: var.$color-neutral-text-onlight;
  text-align: center;
  max-height: 520px;
  display: flex;
  overflow: hidden;
}

.outer-grid-container {
  padding-top: fn.spacing(4);
  justify-content: center;
  overflow: hidden;
}

.grid-item-text {
  order: 1;
}

.title {
  padding-bottom: fn.spacing(3);
  line-height: 140%;
}

.button {
  margin: 0 fn.spacing(0.3);
  z-index: 1;
}

.alt-cta {
  align-self: center;
  padding-top: fn.spacing(5);
}

.image-container {
  min-width: 320px;
  transform: translate(fn.spacing(0), fn.spacing(2));
}

.grid-item-image {
  order: 2;
  width: inherit; //critical for fitting image container to grid item container
  height: inherit; //critical for fitting image container to grid item container
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: fn.spacing(3);
}

/* Desktop (Xlarge or greater) styles */
@media screen and (min-width: var.$breakpoint-xl) {
  .closer-statement-section {
    text-align: left;
    height: 339px;
  }

  .outer-grid-container {
    padding-top: 0;
    max-width: 920px;
    height: 339px;
    margin: auto;
  }

  .text-container {
    padding: 0;
    max-width: 450px;
  }

  .title {
    padding-bottom: fn.spacing(4);
  }

  .cta-container {
    margin: 2px;
    display: flex;
  }

  .alt-cta {
    padding: 0 0 0 fn.spacing(5);
  }
}
