@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.title-divider {
  margin-bottom: fn.spacing(4);
}

.title {
  text-align: center;
  // padding: 0 fn.spacing(5);
}

.subtitle {
  text-align: center;
  margin: fn.spacing(2) 0 0;
  // padding: 0 fn.spacing(5);
}

//Desktop
@media only screen and (min-width: var.$breakpoint-lg) {
  .title-divider {
    margin-bottom: fn.spacing(8);
  }
}
