@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.slide {
  display: flex;
}

.title {
  text-align: center;
  padding: 0 fn.spacing(4);
  margin-bottom: fn.spacing(4);
}

.story-blocks-container {
  padding: fn.spacing(4) 0 0;
}

.button-wrapper {
  align-self: flex-start;
  padding: 0 !important;
}

.link-button {
  /*
  For added context about this !important (and others in the codebase), the selector for the default
  button styles are very specific. Here is an example fo the default specificity that is being
  applied via Sunbeam that makes !important required:
  .css-84ryk3-MuiButtonBase-root-MuiButton-root-containedStyles-buttonHoverStyles-buttonHoverStyles-iconStyles-baseStyles.MuiButton-sizeMedium
  */
  padding: 0 !important; // It's overwritten by sunbeam otherwise
  background: none;
  border: none;
  color: var.$color-primary-achieve-blue;
  cursor: pointer;
  margin: 0;

  &:hover {
    background: none !important; // It's overwritten by sunbeam otherwise
    text-decoration: underline;
  }
}

.block-img {
  width: 100%;
  height: 264px;
  position: relative;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 fn.spacing(1);
  text-align: center;
  width: 100%;
}

.text-center p {
  text-align: left;
  width: 100%;
}

.content-box {
  width: 100%;
  overflow: hidden;
  min-height: fn.spacing(31);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.box-media-text {
  display: flex;
  justify-content: space-between;
  padding: fn.spacing(6) fn.spacing(6);
  overflow: hidden;

  div {
    display: flex;
    justify-content: center;
  }
}

.eyebrow {
  color: var.$color-primary-achieve-blue;
  text-align: center;
  margin-bottom: fn.spacing(2);
  text-transform: uppercase;
  letter-spacing: 0.12em; // TODO - check if this text style should live in Sunbeam
}

@media only screen and (min-width: var.$breakpoint-md) {
  .box-media-text {
    min-height: fn.spacing(63);
    padding: fn.spacing(12) 0;
  }

  .text-center {
    justify-content: space-between;
    text-align: left;
    padding: 0 fn.spacing(8);
  }

  .block-img {
    height: 100%;
  }
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .story-blocks-container {
    padding: fn.spacing(8) 0 0;
  }

  .eyebrow {
    margin-bottom: fn.spacing(4);
  }

  .title {
    margin-bottom: fn.spacing(8);
  }
}

@media only screen and (min-width: var.$breakpoint-xl) {
  .text-center {
    height: auto;
  }
}
