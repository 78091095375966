@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.button {
  width: auto;
  padding: fn.spacing(1) fn.spacing(2) !important; //Once again styles do not apply
  color: var.$color-neutral-white;
  position: relative;
}

.a {
  text-decoration: none;
  color: inherit;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.card {
  background: var.$color-neutral-background-default;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: fn.spacing(5) fn.spacing(3);
  // max-width: 343px;
  // min-height: 340px;
  height: 100%;
  border: 1px solid var.$color-error-text-disabled;
  border-radius: var.$card-border-radius;
  text-align: center;
  position: relative;
}

.card-image {
  margin-bottom: fn.spacing(3);
}

.card-title {
  // padding: 0 fn.spacing(2);
  margin-bottom: fn.spacing(1);
}

.card-subtitle {
  // padding: 0 fn.spacing(2);
  margin-bottom: fn.spacing(3);
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .card {
    // max-width: 384px;
    padding: fn.spacing(6) fn.spacing(4);
  }

  .card-title {
    // padding: fn.spacing(2) 0 0 0;
  }

  .card-subtitle {
    // padding: 0 fn.spacing(1) 0 0;
    flex-grow: 2;
  }
}
