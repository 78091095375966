@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  text-align: center;
  padding: fn.spacing(4);
}

.title {
  color: var.$color-neutral-white;
  line-height: 120%;
}

.subtitle {
  color: var.$color-neutral-white;
  margin-top: fn.spacing(3.5);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8);
  }

  .subtitle {
    margin-top: fn.spacing(4);
  }
}
