@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  // offset bottom padding since erp cards having a bottom padding of spacing(2)
  padding: fn.spacing(4) fn.spacing(0) fn.spacing(2);
}

.outer-grid-container {
  justify-content: center;
}

.grid-wrapper {
  max-width: 1080px;
}

.grid-item-container {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    // offset bottom padding since erp cards on desktop having a bottom padding of spacing(2)
    padding: fn.spacing(8) 0 fn.spacing(6);
  }
}
