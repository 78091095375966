@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(4) fn.spacing(0) fn.spacing(2);
}

.body {
  padding: fn.spacing(5) fn.spacing(0);
}

.title {
  text-align: center;
  margin-bottom: fn.spacing(4);
}

.eyebrow {
  color: var.$color-primary-achieve-blue;
  text-align: center;
  padding-bottom: fn.spacing(2);
  text-transform: uppercase;
  letter-spacing: 0.12em; // TODO - check if this text style should live in Sunbeam
}

.collapse-button {
  width: 100%;
  margin-top: fn.spacing(4);
}

.block-img {
  width: 100%;
  height: 264px; //500px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.open-quote {
  font-family: 'DM Sans', sans-serif;
  font-size: 57px;
  text-align: left;
  color: white;
  width: 100%;
  line-height: 0.5em;
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: fn.spacing(2) fn.spacing(5) 0 fn.spacing(5);
  quotes: '  ' ' \201D';
  max-height: 264px;

  p::before {
    content: open-quote;
  }

  p::after {
    content: close-quote;
  }
}

.small-body {
  max-height: 200px;
  overflow: hidden;
}

@media only screen and (min-width: var.$breakpoint-md) {
  .content {
    max-height: 545px;
    padding: 0 fn.spacing(5);
  }

  .block-img {
    height: 545px;
    margin-top: fn.spacing(2);
  }

  .open-quote {
    font-size: 72px;
  }
}
@media only screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8) 0;
  }

  .eyebrow {
    padding-bottom: fn.spacing(4);
  }

  .title {
    margin-bottom: fn.spacing(8);
  }

  .body {
    padding: fn.spacing(0) fn.spacing(10);
  }

  .content {
    padding: 0 fn.spacing(5);
  }

  .block-img {
    margin-top: fn.spacing(0);
  }
}

@media only screen and (min-width: var.$breakpoint-xl) {
  .content {
    padding: 0 fn.spacing(18);
  }
}
