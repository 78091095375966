@use 'styles/variables' as var;
@use 'styles/functions' as fn;

//Mobile styles
.about-closer-statement-section {
  background-color: var.$color-primary-achieve-blue;
  color: var.$color-neutral-white;
  text-align: center;
  padding: fn.spacing(5) 0 0;

  .outer-grid-container {
    justify-content: center;
    overflow: hidden;
  }

  .grid-item-image {
    order: 2;
    margin: fn.spacing(-16) fn.spacing(-10) 0;

    .image-container {
      min-width: 400px;
      transform: translate(fn.spacing(1), fn.spacing(16));
    }
  }

  .grid-item-text {
    order: 1;

    .text-container {
      padding: 0 fn.spacing(5) fn.spacing(2);

      .title {
        padding-bottom: fn.spacing(1);
      }

      .subtitle {
        padding-bottom: fn.spacing(3);
      }

      .button {
        background-color: var.$color-primary-highlight-blue;
        color: var.$color-primary-achieve-blue;
        padding: fn.spacing(1) fn.spacing(9);
      }
    }
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .about-closer-statement-section {
    text-align: left;
    padding: 0 fn.spacing(5);

    .outer-grid-container {
      justify-content: flex-start;
    }

    .grid-item-image {
      order: 1;
      margin: 0;

      .image-container {
        margin: 0 0 0 fn.spacing(2);
        min-width: 0;
        max-width: 620px;
        transform: translate(fn.spacing(-4), fn.spacing(6));
      }
    }

    .grid-item-text {
      order: 2;

      .text-container {
        padding: 0 0 0 fn.spacing(2);

        .title {
          padding-bottom: fn.spacing(4);
        }

        .subtitle {
          padding-bottom: fn.spacing(6);
          max-width: 530px;
        }
      }
    }
  }
}
