@use 'styles/variables' as var;

.breadcrumb {
  .content-icon {
    display: flex;
    align-items: center;
  }

  .icon {
    color: var.$color-primary-achieve-blue;
    cursor: pointer;
    font-weight: 700;
    width: 14px;
    height: 14px;
  }

  .text {
    color: var.$color-primary-achieve-blue;
    margin-left: 10px;
    line-height: 0;
  }
}
