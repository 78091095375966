@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.image-grid-section {
  padding-left: 0;
  padding-right: 0;
}

.copy-section-container {
  padding: fn.spacing(4) 0;
}

.pre-heading,
.heading {
  margin-bottom: fn.spacing(2);
}

.sub-heading {
  margin-bottom: fn.spacing(3);
}

.main-copy {
  padding-left: var.$default-gutter-width;
  padding-right: var.$default-gutter-width;
}

.grid-image {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: var.$breakpoint-md) {
  .main-copy {
    padding-left: var.$medium-and-up-gutter-width;
    padding-right: var.$medium-and-up-gutter-width;
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  .copy-section-container {
    padding: fn.spacing(8) 0;
  }

  .grid-image {
    max-width: 465px;
    max-height: 465px;
  }
}
