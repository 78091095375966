@use 'styles/variables' as var;
@use 'styles/functions' as fn;

//Mobile
.section {
  padding: fn.spacing(4) 0;
}

//Desktop
@media only screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8) 0;
  }
}
