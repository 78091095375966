@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.anchor-links {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline !important; //Not working on ephimeral otherwise - should be adressed.
  }
}

.image-container {
  border-radius: 12px;
  overflow: hidden;
}

.card-story-type {
  margin-top: fn.spacing(1.5);
  margin-bottom: fn.spacing(1.5);
}

.card-title {
  text-align: center;
}

.card-date {
  margin-top: fn.spacing(1.5);
  margin-bottom: fn.spacing(2);
}

.card-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 286px;
}

.link-container {
  margin-bottom: fn.spacing(7);
}

@media only screen and (min-width: (var.$breakpoint-xl)) {
  .card-story-type {
    margin-top: fn.spacing(3);
    margin-bottom: fn.spacing(2);
  }

  .card-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 100%;
    max-width: 390px;
  }

  .card-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .card-date {
    margin-top: fn.spacing(2);
    margin-bottom: fn.spacing(2.5);
  }

  .card-title {
    text-align: left;
  }
}
