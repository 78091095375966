@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(4) 0;
}

.section-breakline {
  padding: fn.spacing(6) fn.spacing(5.75);
}

.grid-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: fn.spacing(5.25);
}

.card-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  .value-prop-grid-separator {
    display: none;
  }

  &.breakline {
    .value-prop-grid-card {
      align-self: center;
    }

    .value-prop-grid-separator {
      display: flex;
      min-width: 285px;
      min-height: 93px;
      align-items: center;

      div {
        width: 100%;
        border-bottom: 0.5px solid var.$color-neutral-gray-eighty;
      }
    }
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: fn.spacing(6);
}

// These hard coded button sizes should be in line with styles from Sunbeam.
// TODO - Check with Design
.button {
  width: 293px;
  height: 47px;
}

.typography {
  min-width: 105px;
  text-align: center;
}

.title-divider-wrapper {
  margin-bottom: 0;

  > {
    padding: 0;
  }
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-sm) {
  .section,
  .section-breakline {
    display: flex;
    justify-content: center;

    .container-grid {
      // max-width: 470px;

      .title-divider-wrapper {
        h2 {
          padding: 0;
        }
      }
    }
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .grid-wrapper {
    margin-bottom: fn.spacing(8);
  }

  .section,
  .section-breakline {
    padding: fn.spacing(8);

    .container-grid {
      max-width: 100%;
    }
  }

  .value-prop-title-divider {
    margin-bottom: fn.spacing(7);
  }

  .button-wrapper {
    margin-top: fn.spacing(8);
  }

  .card-grid {
    flex-direction: row;
    justify-content: center;

    .value-prop-grid-card {
      width: 290px;
    }

    .value-prop-grid-separator,
    &.breakline .value-prop-grid-separator {
      display: flex;
      justify-content: center;
      min-width: 93px;
      width: 93px;

      div {
        width: 0;
        height: 100%;
        border-left: 0.5px solid var.$color-neutral-gray-eighty;
      }
    }
  }
}
