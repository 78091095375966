@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.about-us-leadership-section {
  padding: fn.spacing(4) 0;
}

.item {
  display: flex;
  justify-content: center;
}

.collapsible {
  margin-top: fn.spacing(3);
}

.content-container {
  display: flex;
  flex-direction: column;
}

.collapse-button {
  margin-top: fn.spacing(8);
}

.title {
  margin-bottom: fn.spacing(0);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .about-us-leadership-section {
    padding: fn.spacing(8) 0;
  }
}
