@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.home-by-the-numbers-container {
  padding: fn.spacing(4) 0 0;
}

.disclaimer {
  padding: fn.spacing(1);
  margin-top: fn.spacing(2);
  text-align: center;
  color: var.$color-neutral-black;
}

@media only screen and (min-width: var.$breakpoint-xs) {
  .disclaimer {
    padding: 0 fn.spacing(6);
  }
}

@media only screen and (min-width: var.$breakpoint-md) {
  .home-by-the-numbers-container {
    padding: fn.spacing(8) 0 0;
  }

  .disclaimer {
    padding: 0 fn.spacing(24);
  }
}
