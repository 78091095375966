@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.filter-grid {
  display: flex;
  direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.label {
  margin-right: fn.spacing(5);
}

@media only screen and (min-width: var.$breakpoint-sm) {
  .filter-grid {
    display: flex;
    direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
