@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.mobile-section {
  text-align: center;
}

.mobile-section > div {
  padding: 0;
}

.paper {
  height: 70vh;
  width: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  margin: 0;
}

.desktop-hero-image {
  border-radius: 15px;
}

.breadcrumb-text {
  color: var.$color-neutral-white;
  text-align: center;
}

.title-text {
  color: var.$color-neutral-white;
  margin-bottom: fn.spacing(2);
  padding: 0 fn.spacing(10);
  text-align: center;
}

.subtitle-text {
  margin-bottom: fn.spacing(6);
  text-align: center;
  padding: 0 fn.spacing(6);
}

.subtitle-text a {
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
}

@media screen and (min-width: var.$breakpoint-md) {
  .paper {
    height: 75vh;
  }

  .subtitle-text a {
    color: var.$color-primary-achieve-blue;
  }

  .title-text {
    color: inherit;
    text-align: left;
    padding: 0;
    margin-bottom: fn.spacing(2);
  }

  .subtitle-text {
    text-align: left;
    padding: 0;
    margin-bottom: fn.spacing(4);
  }

  .breadcrumb-text {
    color: var.$color-primary-achieve-blue;
    text-align: left;
    padding-top: fn.spacing(3);
    padding-bottom: fn.spacing(3);
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  .subtitle-text {
    margin-bottom: fn.spacing(6);
  }

  .press-hero-section {
    padding: fn.spacing(4) 0 fn.spacing(8);
  }
}
