@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.mobile-hero-image {
  width: 100%;
  height: 600px;
  position: relative;
  z-index: 0;
  padding: fn.spacing(4);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(rgb(0 0 0 / 20%), rgb(0 0 0 / 20%));
  }
}

.mobile-hero-content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
}

.desktop-hero-image {
  border-radius: 15px;
}

.mobile-blue-section {
  background-color: var.$color-primary-achieve-blue;
  padding: fn.spacing(31) fn.spacing(4) fn.spacing(4) fn.spacing(4);
}

.mobile-subtitle-text {
  color: var.$color-neutral-white;
  text-align: center;
}

.breadcrumb-text {
  padding-bottom: fn.spacing(1);
  padding-top: fn.spacing(6);
  color: var.$color-neutral-white;
  text-align: center;
}

.title-text {
  color: var.$color-neutral-white;
  margin-bottom: fn.spacing(4);
  text-align: center;
}

.slider-tool-paper {
  box-shadow: none;
  background-color: var.$color-primary-highlight-blue;
  border-radius: fn.spacing(2);
  padding: fn.spacing(4);
  position: absolute;
  z-index: 2;
  top: 360px;
  left: 5%;
  width: 90%;
}

.slider-tool-wrapper {
  width: 100%;
  margin: 0 0 fn.spacing(3) 0;
  padding-right: 0;
}

.slider-tool-slider {
  width: 100%;
}

.slider-tool-title {
  text-align: center;
  margin-bottom: fn.spacing(2);
}

.slider-tool-min-mark {
  // value didn't fit within standard spacing
  margin-left: 50px;
}

.slider-tool-max-mark {
  margin-left: fn.spacing(-10);
}

.marks {
  display: none;
}

.slider-tool-cta {
  margin-top: 0;
  min-width: max-content;
}

.slider-tool-value {
  color: var.$color-primary-achieve-blue;
  text-align: center;
}

.slider-tool-stats {
  padding-top: fn.spacing(2);
  display: inline-block;
  margin-right: fn.spacing(2);
}

.slider-tool-stats-title {
  margin-top: fn.spacing(3);
}

@media screen and (min-width: var.$breakpoint-md) {
  .mobile-hero-image {
    width: 100%;
    height: 400px;
  }

  .slider-tool-paper {
    top: 270px;
    left: 5%;
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  .breadcrumb-text {
    color: var.$color-primary-achieve-blue;
    text-align: left;
    padding-top: fn.spacing(3);
    padding-bottom: fn.spacing(3);
  }

  .slider-tool-wrapper {
    margin: fn.spacing(1) 0 fn.spacing(3) 0;
    padding-right: fn.spacing(4);
  }

  .slider-tool-title {
    text-align: left;
    margin-bottom: 0;
  }

  .slider-tool-cta {
    margin-top: 0;
  }

  .slider-tool-stats {
    margin-right: fn.spacing(5);
  }

  .slider-tool-paper {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: var.$spacing;
    margin-top: fn.spacing(6);
    width: 100%;
  }

  .title-text {
    color: inherit;
    text-align: left;
  }

  .product-hero-form-container {
    padding-right: fn.spacing(4);
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .slider-tool-cta {
    margin-top: fn.spacing(6);
  }

  .slider-tool-stats-title {
    margin-top: 0;
  }
}
