@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  background-color: var.$color-primary-highlight-blue;
  padding: fn.spacing(4) 0;
}

@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8) 0;
  }
}
