@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.faq-section {
  padding: fn.spacing(4) 0;
}

.accordion {
  &:not(:first-of-type) {
    padding-top: fn.spacing(4);
  }
}

.accordion-summary {
  padding-right: 0;
}

.accordion-summary-text {
  padding-right: fn.spacing(2);
}

.button {
  height: 47px;
  width: 293px;
}

.expand-icon-selected {
  color: var.$color-primary-achieve-blue;
}

.title {
  margin-bottom: fn.spacing(4);
}

@media screen and (min-width: var.$breakpoint-lg) {
  .faq-section {
    padding: fn.spacing(8) 0;
  }

  .title {
    margin-bottom: fn.spacing(8);
  }
}
