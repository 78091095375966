@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  background-color: var.$color-neutral-background-default;
  padding-top: fn.spacing(4);
  padding-bottom: fn.spacing(4);
}

.eyebrow {
  color: var.$color-primary-achieve-blue;
  text-align: center;
  padding-bottom: fn.spacing(5);
  text-transform: uppercase;
  letter-spacing: 0.12em; // TODO - check if this text style should live in Sunbeam
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  min-height: 416px;
  padding: fn.spacing(4) fn.spacing(3) fn.spacing(7);
}

.block-img {
  width: 100%;
  min-height: 416px;
  position: relative;

  span {
    border-radius: 16px;
  }
}

.sub-title {
  padding-top: fn.spacing(2);
}

.paper {
  border: 1px solid var.$color-paper-white;
  border-radius: 16px;
  padding: fn.spacing(1.5);
}

.paper-carousel-title-divider {
  margin-bottom: fn.spacing(6);
}

.carousel-active {
  // the Carousel component has a more specific css that can’t be overridden so we use !important
  background-color: var.$color-primary-background-default !important;
}

.hero-carousel-wrapper-indicators button {
  background-color: var.$color-neutral-grey-4;
}

.hero-carousel-wrapper-indicators {
  // the Carousel component has a more specific css that can’t be overridden so we use !important
  background-color: transparent !important;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-md) {
  .sub-title {
    padding-top: fn.spacing(4);
  }

  .content {
    padding: fn.spacing(4) fn.spacing(15) fn.spacing(4) fn.spacing(4);
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  .block-img {
    min-height: 550px;
  }

  .content {
    padding: fn.spacing(8) fn.spacing(40) fn.spacing(8) fn.spacing(8);
    min-height: 550px;
  }

  .section {
    padding-top: fn.spacing(8);
    padding-bottom: fn.spacing(8);
  }

  .paper-carousel-title-divider {
    margin-bottom: fn.spacing(8);
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .content {
    padding: fn.spacing(8) fn.spacing(55) fn.spacing(8) fn.spacing(8);
  }
}
